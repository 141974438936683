function AutoMessagePage({ lang, business_data, toast }) {
  const [info, setInfo] = React.useState({});
  const [tempInfo, setTempInfo] = React.useState({});
  const [listTeam, setListTeam] = React.useState([]);
  const [isOpenGreetings, setIsOpenGreetings] = React.useState(false);
  const [isOpenAutoReply, setIsOpenAutoReply] = React.useState(false);
  const [isOpenAutoReplyBusinessHours, setIsOpenAutoReplyBusinessHours] =
    React.useState(false);
  const [isOpenGreetingsBusinessHours, setIsOpenGreetingsBusinessHours] =
    React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getAutoMessage();
    getListTeam();
  }, []);

  const getAutoMessage = () => {
    axios({
      method: "post",
      url:
        backend +
        "app/" +
        business_data.uid +
        "/" +
        business_data.pid +
        "/settings",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: {
        appId: business_data.app_id,
      },
    }).then((res) => {
      if (res.data.result === 0) {
        setInfo(res.data.data);
        setTempInfo(res.data.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });
  };

  const getListTeam = () => {
    axios({
      method: "post",
      url:
        backend +
        "team/" +
        business_data.uid +
        "/" +
        business_data.pid +
        "/" +
        business_data.app_id +
        "/list",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        version: main.version,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        setListTeam(res.data.data);
      } else {
        setIsLoading(false);
      }
    });
  };

  const updateInfo = () => {
    setIsLoading(true);
    axios({
      method: "put",
      url:
        backend +
        "app/" +
        business_data.uid +
        "/" +
        business_data.pid +
        "/settings",
      headers: {
        "Content-type": "application/json;charset=UTF-8",
        "X-VC-Access-Token": business_data.access,
        Authorization: "Bearer " + business_data.token,
      },
      data: JSON.stringify({
        appId: business_data.app_id,
        info: info.settingInfo,
      }),
    }).then((res) => {
      if (res.data.result === 0) {
        toast.success(lang.__auto_message_save_toast_success);
        setTempInfo(info);
        setIsLoading(false);
        setIsOpenAutoReply(false);
        setIsOpenAutoReplyBusinessHours(false);
        setIsOpenGreetings(false);
        setIsOpenGreetingsBusinessHours(false);
      } else {
        toast.error(lang.__auto_message_save_toast_fail);
        setIsLoading(false);
      }
    });
  };

  const cancel = () => {
    setInfo(tempInfo);
  };

  return (
    <div style={{ height: "100%" }}>
      {!isLoading ? (
        <div style={{ height: "100%" }}>
          <div
            class="sp-row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70px",
              padding: "0 20px",
              borderBottom: "1px solid rgb(225, 231, 242)",
            }}
          >
            <div class="sp-col">
              <span class="sp-label">
                {lang.__auto_message}
              </span>
              <ReactTooltip text={lang.__manual_auto_message}>
                <a
                href="https://help.readyplanet.com/rchat/auto-message"
                target="_blank"
              >
                <i
                  class="icon-help-circled sp-link"
                  tooltip-placement="right"
                ></i>
              </a>
          </ReactTooltip>
            </div>
          </div>
          <div style={{ height: "calc(100% - 70px)", display: "flex", flexDirection: "column"}}>
            <Body style={{ "flex-grow": "1", paddingTop: 20 }}>
            {info.settingInfo.business_hours_status ? (
              <TitleBusinessHours>
                {lang.__business_hours}
              </TitleBusinessHours>
            ) : (
              ""
            )}
            <ToggleClick
              style={{ cursor: "pointer" }}
              onClick={() => setIsOpenGreetings(!isOpenGreetings)}
              data-toggle="collapse"
              data-target="#GreetingMessage"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div>{lang.__setting_greeting_message}</div>
              <IconChevron toggle={isOpenGreetings}>
                <i class="fa fa-chevron-down"></i>
              </IconChevron>
            </ToggleClick>

            <div class="collapse" id="GreetingMessage">
              <GreetingMessage
                key={"greeting_message"}
                lang={lang}
                greetingMessageA={info.settingInfo.greeting_message}
                info={info}
                setInfo={setInfo}
                listTeam={listTeam}
                status={"business_hours"}
              />
            </div>

            <ToggleClick
              onClick={() => setIsOpenAutoReply(!isOpenAutoReply)}
              data-toggle="collapse"
              data-target="#AutoReplyMessage"
              aria-expanded="false"
              aria-controls="collapseExample"
            >
              <div>{lang.__setting_auto_reply_message}</div>
              <IconChevron toggle={isOpenAutoReply}>
                <i class="fa fa-chevron-down"></i>
              </IconChevron>
            </ToggleClick>
            <div class="collapse" id="AutoReplyMessage">
              <AutoReplyMessage
                key={"AutoReplyMessage"}
                lang={lang}
                info={info}
                setInfo={setInfo}
                status={"business_hours"}
              />
            </div>
            {info.settingInfo.business_hours_status ? (
              <div>
                <div class="sp-row">
                  <TitleBusinessHours>
                    {lang.__business_hours_outside}
                  </TitleBusinessHours>
                </div>
                <ToggleClick
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setIsOpenGreetingsBusinessHours(
                      !isOpenGreetingsBusinessHours
                    )
                  }
                  data-toggle="collapse"
                  data-target="#GreetingMessageBusinessHours"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div>{lang.__setting_greeting_message}</div>
                  <IconChevron toggle={isOpenGreetingsBusinessHours}>
                    <i class="fa fa-chevron-down"></i>
                  </IconChevron>
                </ToggleClick>
                <div class="collapse" id="GreetingMessageBusinessHours">
                  <GreetingMessage
                    key={"GreetingMessageOutSide"}
                    lang={lang}
                    greetingMessageA={info.settingInfo.greeting_message}
                    info={info}
                    setInfo={setInfo}
                    listTeam={listTeam}
                    status={"outside_business_hours"}
                  />
                </div>

                <ToggleClick
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setIsOpenAutoReplyBusinessHours(
                      !isOpenAutoReplyBusinessHours
                    )
                  }
                  data-toggle="collapse"
                  data-target="#AutoReplyMessageBusinessHours"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <div>{lang.__setting_auto_reply_message}</div>
                  <IconChevron toggle={isOpenAutoReplyBusinessHours}>
                    <i class="fa fa-chevron-down"></i>
                  </IconChevron>
                </ToggleClick>
                <div class="collapse" id="AutoReplyMessageBusinessHours">
                  <AutoReplyMessage
                    key={"AutoReplyMessageOutSide"}
                    lang={lang}
                    info={info}
                    setInfo={setInfo}
                    status={"outside_business_hours"}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </Body>
          <Footer>
            <button class="sp-btn -link" onClick={() => cancel()}>
              {lang.__cancel}
            </button>
            <button
              class="sp-btn -blue"
              onClick={() => updateInfo()}
              disabled={isLoading}
            >
              {isLoading && <i class="icon-circle-notch animate-spin"></i>}
              <span>{lang.__save}</span>
            </button>
          </Footer>
            </div>
        </div>
      ) : (
        <div class="sp-placeholder">
          <div class="sp-text-help">
            <i class="icon icon-circle-notch animate-spin"></i>{" "}
            <span>{lang.__loading}</span>
          </div>
        </div>
      )}
    </div>
  );
}

const ToggleClick = window.styled.div`
${(props) =>
  props.onClick
    ? `
        cursor: pointer;
      `
    : ``}

display: flex;
align-items: center;
justify-content: space-between;
height: 50px;
padding: 0 20px;
margin: 0 8%;
margin-bottom: 20px;
border-bottom: 1px solid #E1E7F2;
@media (max-width: 768px) {
  margin: 0 0;
  margin-bottom: 20px;
}
`;

const TitleBusinessHours = window.styled.div`
  padding-left: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    padding-left: 0px;
  }
  `;

